/* .App {
  text-align: center;
} */
@import './fonts/aller-cufonfonts-webfont/style.css';
@import './fonts/font-khmer/style.css';
@import url('https://fonts.googleapis.com/css?family=Dosis:700,500,300');

body {
  font-family: dosis, kh_siemreap;
  font-size: larger;
}

button:focus{
  outline: none; /* remove outline from button */
}

input[type='text']:disabled {
  background: hsl(0, 0%, 95%);
  border-color: hsl(0, 0%, 90%);
}

::placeholder {
  font-family: dosis, kh_siemreap;
  font-size: larger;
  text-transform: lowercase;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Aller';
}

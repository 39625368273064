@import url(https://fonts.googleapis.com/css?family=Dosis:700,500,300);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loginForm {
  border-radius: 0.5em;
  width: 500px;
}
.text-input {
  height: 90px;
}

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'Aller';
font-style: normal;
font-weight: normal;
src: local('Aller'), url(/static/media/Aller_Rg.a644e18a.woff) format('woff');
}


@font-face {
font-family: 'Aller Italic';
font-style: normal;
font-weight: normal;
src: local('Aller Italic'), url(/static/media/Aller_It.11365a16.woff) format('woff');
}


@font-face {
font-family: 'Aller Bold';
font-style: normal;
font-weight: normal;
src: local('Aller Bold'), url(/static/media/Aller_Bd.35639100.woff) format('woff');
}


@font-face {
font-family: 'Aller Bold Italic';
font-style: normal;
font-weight: normal;
src: local('Aller Bold Italic'), url(/static/media/Aller_BdIt.2146c7a0.woff) format('woff');
}


@font-face {
font-family: 'Aller Display';
font-style: normal;
font-weight: normal;
src: local('Aller Display'), url(/static/media/AllerDisplay.342f525b.woff) format('woff');
}


@font-face {
font-family: 'Aller Light Italic';
font-style: normal;
font-weight: normal;
src: local('Aller Light Italic'), url(/static/media/Aller_LtIt.1c061752.woff) format('woff');
}
@font-face {
    font-family: 'kh_siemreap';
    src: url(/static/media/KhmerOS_siemreap.d586f04d.ttf) format('truetype');
}

@font-face {
    font-family: 'kh_muollight';
    src: url(/static/media/KhmerOS_muollight.7b996be2.ttf) format('truetype');
}
/* .App {
  text-align: center;
} */

body {
  font-family: dosis, kh_siemreap;
  font-size: larger;
}

button:focus{
  outline: none; /* remove outline from button */
}

input[type='text']:disabled {
  background: hsl(0, 0%, 95%);
  border-color: hsl(0, 0%, 90%);
}

::-webkit-input-placeholder {
  font-family: dosis, kh_siemreap;
  font-size: larger;
  text-transform: lowercase;
}

:-ms-input-placeholder {
  font-family: dosis, kh_siemreap;
  font-size: larger;
  text-transform: lowercase;
}

::placeholder {
  font-family: dosis, kh_siemreap;
  font-size: larger;
  text-transform: lowercase;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Aller';
}



